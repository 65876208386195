import React, { useContext, useEffect, useRef, useState } from 'react';

import { Box, useMediaQuery } from '@mui/material';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { bool } from 'prop-types';
import { Form, Outlet, useNavigation, useParams } from 'react-router-dom';

import ActionFormProvider, { ActionFormContext } from '../../../context/ActionForm';
import { AnalyticsContext } from '../../../context/Analytics';
import { CustomerRequestContext } from '../../../context/CustomerRequest';
import Header from './Header';
import TrustBanner from './TrustBanner';
import Loading from '../Loading';

const ActionFormWrapper = ({ stripeLoaded }) => {
  const { formRef } = useContext(ActionFormContext);
  const { value: customerRequestData, customerInfo: customerSessionData } = useContext(CustomerRequestContext);
  const { userId } = useContext(AnalyticsContext);
  const { step } = useParams();

  const isMobile = useMediaQuery(({ breakpoints }) => breakpoints.down('sm'));

  return (
    <Form method="post" ref={formRef}>
      {userId ? <input type="hidden" name="userId" value={userId} /> : null}
      {customerRequestData ? (
        <input type="hidden" name="customerRequestData" value={JSON.stringify(customerRequestData)} />
      ) : null}
      {customerSessionData ? (
        <input type="hidden" name="customerSessionData" value={JSON.stringify(customerSessionData)} />
      ) : null}
      <Box px={{ xs: 2, sm: 2 }} pb={{ xs: 2, sm: 0 }}>
        {step !== 'checkout' || stripeLoaded ? <Outlet /> : null}
      </Box>
      {isMobile ? (
        <Box pt={{ xs: 0 }} display="flex" width="100%">
          <TrustBanner />
        </Box>
      ) : null}
    </Form>
  );
};

ActionFormWrapper.propTypes = {
  stripeLoaded: bool,
};

ActionFormWrapper.defaultProps = {
  stripeLoaded: false,
};

const Layout = () => {
  const { step } = useParams();
  const { state } = useNavigation();
  const isMobile = useMediaQuery(({ breakpoints }) => breakpoints.down('sm'));

  const [stripePromise, setStripePromise] = useState(null);
  const containerRef = useRef<HTMLElement>();

  useEffect(() => {
    if (step === 'checkout') {
      setStripePromise(loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY));
    }
    if (containerRef.current) {
      containerRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [step]);

  return (
    <Box minHeight={{ xs: 'calc(100vh - 51px)', md: 'calc(100vh - 105px)' }} display="flex" flexWrap="wrap">
      <Header />
      {!isMobile ? (
        <Box pt={{ sm: 8, md: 11 }} display="flex" width="100%">
          <TrustBanner />
        </Box>
      ) : null}
      <Box
        ref={containerRef}
        width="100%"
        height="100%"
        pt={{ xs: 10, sm: 5 }}
        pb={{ xs: 20, sm: 15 }}
        bgcolor="background.default"
        display="flex"
        flexDirection="column"
      >
        {state === 'loading' ? (
          <Loading />
        ) : (
          <ActionFormProvider>
            {step === 'checkout' && stripePromise ? (
              <Elements stripe={stripePromise}>
                <ActionFormWrapper stripeLoaded />
              </Elements>
            ) : (
              <ActionFormWrapper />
            )}
          </ActionFormProvider>
        )}
      </Box>
    </Box>
  );
};

export default Layout;
