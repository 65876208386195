import React, { useState } from 'react';

import { useParams, useSearchParams } from 'react-router-dom';

import { Close, Menu } from '@mui/icons-material';
import {
  AppBar,
  Box,
  Drawer,
  IconButton,
  LinearProgress,
  Link,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import PHONE_NUMBERS from '../../../../constants/phone_numbers';
import useAffiliate from '../../../../hooks/useAffiliate';
import useMarketingHeader from '../../../../hooks/useMarketingHeader';
import HamburgerItems from './HamburgerItems';

const PlusIcon = `${process.env.PUBLIC_URL}/images/header/plus.svg`;
const HopperLogo = `${process.env.PUBLIC_URL}/images/header/hopper.svg`;

const Header = () => {
  const [{ logo: headerLogo }]: any[] = useMarketingHeader();
  const [searchParams] = useSearchParams();
  const theme = useTheme();
  const { step } = useParams();

  const utmSource = searchParams.get('utm_source');
  const utmContent = searchParams.get('utm_content');

  const enableAffiliateCobranding = utmSource && (utmContent?.includes('partnerships') || utmContent?.includes('affiliate'));

  // Only call Hygraph for affiliate when enabled with utm params
  const [affiliate] = useAffiliate({ affiliate: enableAffiliateCobranding ? utmSource : null });
  const [drawerMenuOpen, setDrawerMenuOpen] = useState(false);

  const toggleDrawerMenu = () => setDrawerMenuOpen(!drawerMenuOpen);

  const handleClose = () => {};

  const isMobile = useMediaQuery(({ breakpoints }) => breakpoints.down('md'));
  const partnerLogo = isMobile ? affiliate?.mobileSvg : affiliate?.desktopSvg;
  const bellhopLogo = isMobile ? HopperLogo : headerLogo?.url;

  return (
    <Box onMouseLeave={handleClose}>
      <AppBar component={Box} elevation={0} zIndex={10001}>
        <Toolbar component={Box} variant="dense" disableGutters>
          <Box flexGrow={1} display="flex" alignItems="center">
            {affiliate && partnerLogo ? (
              <>
                <Box
                  display="flex"
                  alignItems="center"
                  sx={{
                    '& img': ({ breakpoints }) => ({
                      width: '100%',
                      maxWidth: ({ spacing }) => spacing(5),
                      [breakpoints.up('md')]: {
                        maxWidth: ({ spacing }) => spacing(20),
                      },
                    }),
                  }}
                >
                  <img src={partnerLogo.url} alt={partnerLogo.alt} width="100%" />
                </Box>
                <Box px={2}>
                  <img src={PlusIcon} alt="plus" />
                </Box>
              </>
            ) : null}
            <Box pr={3}>
              {bellhopLogo ? (
                // TODO: Drop non marketing params (quoteId only currently)
                <Link href={typeof window !== 'undefined' ? `/${window.location.search}` : '/'}>
                  <Box
                    display="flex"
                    alignItems="center"
                    sx={{
                      '& img': ({ breakpoints }) => ({
                        width: '100%',
                        maxWidth: ({ spacing }) => spacing(5),
                        [breakpoints.up('md')]: {
                          maxWidth: ({ spacing }) => spacing(20),
                        },
                      }),
                    }}
                  >
                    <img src={bellhopLogo} alt="Bellhop Logo" />
                  </Box>
                </Link>
              ) : null}
            </Box>
          </Box>
          <Box textAlign="right" flexDirection="row" display="flex" alignItems="center" justifyContent="flex-end">
            {/* Phone Link */}
            <Box pr={{ xs: 0, sm: 3, md: 1, lg: 3 }} display="block" flexShrink={0}>
              {step !== 'lead_confirmation' ? (
                <Typography variant="h4">
                  <Link color="textPrimary" href={`tel:${PHONE_NUMBERS.CUSTOMER_SERVICE}`} sx={{ textDecoration: 'none' }}>
                    <span>{PHONE_NUMBERS.CUSTOMER_SERVICE.replace(/^(1-)/, '')}</span>
                  </Link>
                </Typography>
              ) : null}
            </Box>
            {/* Hamburger Menu Icon */}
            <IconButton onClick={toggleDrawerMenu} aria-label="navigation menu">
              {drawerMenuOpen ? <Close /> : <Menu />}
            </IconButton>
          </Box>
        </Toolbar>
        {!drawerMenuOpen ? (
          <Box width="100%" zIndex={10001}>
            <LinearProgress color="primary" variant="determinate" value={100} />
          </Box>
        ) : null}
      </AppBar>
      <Drawer
        open={drawerMenuOpen}
        anchor="right"
        elevation={0}
        sx={{ zIndex: 100, '& .MuiPaper-root': { width: '100vw', [theme.breakpoints.up('md')]: { width: '500px' } } }}
      >
        <HamburgerItems />
      </Drawer>
    </Box>
  );
};

export default Header;
